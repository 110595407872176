import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Container } from './content/models';
import { ContainerService } from './content/services';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private containerService: ContainerService;

  public breadcrumbLabelsMap: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public breadcrumbIdMap: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public labelsMap: any
  public idMap: any
  constructor(
    containerService: ContainerService
    ) { 
      this.containerService = containerService;
    }

  updateTemplatedLabels(labelsMap: any) {
    this.labelsMap = labelsMap
    this.breadcrumbLabelsMap.next(labelsMap);
  }

  checkTemplatedLabels() {
    this.breadcrumbLabelsMap.next(this.labelsMap);
  }

  updateIdLabels() {
    this.breadcrumbIdMap.next(this.idMap);
  }

  clearBreadcrumbs()
  {
    this.labelsMap = [];
  }

  resolvedDefaultURL(url:any)
  {
    if (url){
      let idList = url.match(/\/(\d+)+[\/]?/g)
      if (idList){
        this.idMap = {};
        let ids = idList.map(id => id.replace(/\//g, ''));
        ids.forEach((id) => this.ResolveContainerLabelsById(id));
        return ids 
      }
    }
    return
  }

  private ResolveContainerLabelsById(id)
  {
    this.containerService
            .get(id, 'flat', -1).subscribe(
                res => {
                  this.idMap[id]=res.guid
                  this.updateIdLabels()
                }
                ,
                err => {},
                () => {}
            );
}

}